import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import Footer from "../components/footer"
import Bannerslider from "../components/slider"

import { graphql } from "gatsby"
import { Link } from "gatsby"

import FB from "../images/facebook-icon.svg"
import WEB from "../images/web-icon.svg"
import Insta from "../images/insta.svg"
import ContactHeader from "../components/contactheader"
import OpenTime from "../components/opentime"

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import Newsletter from "../components/newsletter"

const StorePage = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageActive, setImageActive] = useState(false)
  const sdata = data.prismicStore.data

  return (
    <Layout>
      <SEO
        title={sdata.store_title.text}
        description="„help your local store“ soll auf Liefer- und Abholangebote in Sindelfingen und Böblingen während der Corona-Zeit aufmerksam machen und ist für die Restaurants sowie für alle Nutzer komplett kostenfrei."
      />
      <ContactHeader link={`/#newsletter-${sdata.store_title.text}`} />
      <Nav />
      <div className="store-page">
        <Bannerslider images={sdata.store_slider} />
        <div className="grid-container">
          <div className="store-page__intro">
            <img className="store-page__store-logo" src={sdata.store_logo.url} alt={sdata.store_title.text}></img>
            <h2>{sdata.store_title.text}</h2>
            <p className="store-page__kategorie">
              <i>
                {sdata.store_categorie}, {sdata.store_service}
              </i>
            </p>
            <p> {sdata.store_desicription.text} </p>
            {sdata.store_instagram.url && (
              <a
                className="store-page__website"
                href={sdata.store_instagram.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Instagram Icon" src={Insta}></img>
              </a>
            )}
            {sdata.store_facebook.url && (
              <a
                className="store-page__website"
                href={sdata.store_facebook.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Facebook Icon" src={FB}></img>
              </a>
            )}
            {sdata.store_website.url && (
              <a
                className="store-page__website"
                href={sdata.store_website.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Web Icon" src={WEB}></img>
              </a>
            )}
            <OpenTime times={sdata.store_openingtime} show="long" />
          </div>
          <hr></hr>
          <div className="store-page__order-details">
            <h3>Informationen</h3>

            {sdata.store_general_description.text !== "" ? (
              <div className="store-page__general-desc">
                <p> {sdata.store_general_description.text} </p>
              </div>
            ) : null}

            {sdata.store_deliverytime.text !== "" ? (
              <div className="grid-x mb">
                <div className="large-4 cell">
                  <p className="head"> Lieferzeiten </p>
                </div>
                <div className="large-8 cell">
                  <p> {sdata.store_deliverytime.text} </p>
                </div>
              </div>
            ) : null}

            {sdata.store_payment.text && (
              <div className="grid-x mb">
                <div className="large-4 cell">
                  <p className="head"> Zahlungsmöglichkeiten </p>
                </div>
                <div className="large-8 cell">
                  {" "}
                  <p> {sdata.store_payment.text} </p>{" "}
                </div>
              </div>
            )}
            <div className="grid-x mb">
              <div className="large-4 cell">
                <p className="head"> Lieferart </p>
              </div>
              <div className="large-8 cell">
                <p>{sdata.store_service}</p>{" "}
              </div>
            </div>
            <div className="grid-x mb">
              <div className="large-4 cell">
                <p className="head"> Adresse </p>
              </div>
              <div className="large-8 cell">
                <p>
                  {sdata.store_street.text} {sdata.store_streetnumber.text}{" "}
                </p>
                <p>
                  {sdata.store_postalcode.text} {sdata.store_city.text}
                </p>
              </div>
            </div>
            <div className="grid-x mb">
              <div className="large-4 cell">
                <p className="head"> Telefonnummer </p>
              </div>
              <div className="large-8 cell">
                <p>0{sdata.store_phonenumber.toString()}</p>{" "}
              </div>
            </div>
            {sdata.store_longdesc.text && (
              <div className="grid-x mb">
                <div className="large-4 cell">
                  <p className="head"> Zusätzliche Informationen </p>
                </div>
                <div className="large-8 cell">
                  <p>{sdata.store_longdesc.text}</p>{" "}
                </div>
              </div>
            )}

            <a className="store-page__phone" href={`tel:0${sdata.store_phonenumber}`}>
              Jetzt anrufen
            </a>
            <a className="store-page__route" href={sdata.store_route.url} target="_blank" rel="noopener noreferrer">
              Route
            </a>
          </div>
          <hr></hr>
          <div>
            {imageActive && (
              <div className="active-image">
                <p onClick={() => setImageActive(false)}>X</p>
                <div className="active-image__holder">
                  <TransformWrapper>
                    <TransformComponent>
                      <div className="active-image__inner">
                        <img src={selectedImage} alt="Karte" />
                      </div>
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </div>
            )}
            <div className="store-page__menu">
              {sdata.store_categorie === "Einzelhandel" ? <h3>Angebote</h3> : <h3>Speisekarte</h3>}

              <div>
                {sdata.body.map(slice => {
                  switch (slice.slice_type) {
                    case "slice_image":
                      return (
                        <div key={slice.id} className="grid-x grid-margin-x">
                          {slice.items.map(item => {
                            return (
                              <div
                                key={item.image_menu.url}
                                className="large-4 cell medium-4 small-6 oc"
                                onClick={() => {
                                  setSelectedImage(item.image_menu.url)
                                  setImageActive(true)
                                }}
                              >
                                <img className="slice-image" src={item.image_menu.url} alt="Speisekarte"></img>
                              </div>
                            )
                          })}
                        </div>
                      )
                    case "text":
                      return (
                        <div key={slice.id}>
                          {slice.items.map(item => {
                            return (
                              <div className="store-page__meal">
                                {item.slice_meal.raw.map(meal => {
                                  return (
                                    <div>
                                      <p className={meal.type === "heading4" ? "meal-heading" : "meal"}>
                                        {" "}
                                        {meal.text}{" "}
                                      </p>
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          })}
                        </div>
                      )
                    case "slice_link":
                      return (
                        <div key={slice.id}>
                          {slice.items.map(item => {
                            return (
                              <div>
                                <a
                                  className="menu-link"
                                  href={item.link_slice.raw[1].text}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.link_slice.raw[0].text}
                                </a>
                              </div>
                            )
                          })}
                        </div>
                      )
                  }
                })}
              </div>
              <a className="store-page__phone" href={`tel:0${sdata.store_phonenumber}`}>
                Jetzt anrufen
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="store-page">
        <div className="grid-container">
          <h3 className="text-center">Weitere Geschäfte</h3>
          <div className="grid-x grid-margin-x">
            {data.allPrismicStore.edges.map(store => {
              return (
                <div key={store.node.uid} className="large-4 cell">
                  <Link to={store.node.uid} className="storelist-store">
                    <img className="storelist-store__logo" src={store.node.data.store_logo.url} alt="Logo"></img>
                    <div className="storelist-store__content">
                      <h4> {store.node.data.store_title.text} </h4>
                      <p className="storelist-store__categorie">
                        {" "}
                        <i>
                          {store.node.data.store_categorie}, {store.node.data.store_service}
                        </i>{" "}
                      </p>
                      <p> {store.node.data.store_desicription.text.substring(0, 45)}... </p>
                      <div className="storelist-store__lower">
                        <OpenTime times={store.node.data.store_openingtime} show="short" />
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div id={`newsletter-${sdata.store_title.text}`}>
        <Newsletter mode="full" />
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($uid: String!) {
    allPrismicStore(filter: { uid: { ne: $uid } }, limit: 3) {
      edges {
        node {
          uid
          data {
            store_categorie

            store_coordinates {
              latitude
              longitude
            }
            store_title {
              text
            }
            store_desicription {
              text
            }
            store_logo {
              url
            }
            store_phonenumber
            store_openingtime {
              store_times {
                raw {
                  text
                }
              }
            }
            store_service
          }
        }
      }
    }
    prismicStore(uid: { eq: $uid }) {
      data {
        store_title {
          text
        }
        store_city {
          text
        }
        store_categorie
        store_general_description {
          text
        }
        store_desicription {
          text
        }
        store_logo {
          url
        }
        store_instagram {
          url
        }
        store_facebook {
          url
        }
        store_deliverytime {
          text
        }
        store_longdesc {
          text
        }
        store_openingtime {
          store_times {
            raw {
              text
            }
          }
        }
        store_payment {
          text
        }
        store_phonenumber
        store_postalcode {
          text
        }
        store_route {
          url
        }
        store_service
        store_slider {
          slider_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        store_street {
          text
        }
        store_streetnumber {
          text
        }
        store_website {
          url
        }
        body {
          ... on PrismicStoreBodyText {
            id
            slice_type
            items {
              slice_meal {
                raw {
                  type
                  text
                }
              }
            }
          }
          ... on PrismicStoreBodySliceImage {
            id
            slice_type
            items {
              image_menu {
                url
              }
            }
          }
          ... on PrismicStoreBodySliceLink {
            id
            slice_type
            items {
              link_slice {
                raw {
                  text
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StorePage
