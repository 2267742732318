import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Bannerslider = ({ images }) => {
  var settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <Slider {...settings}>
      {images.map(image => {
        return (
          <div key={image.slider_image.localFile.childImageSharp.fluid.src} className="banner-slider">
            <div className="banner-slider__inner">
              <Img fluid={image.slider_image.localFile.childImageSharp.fluid}></Img>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
export default Bannerslider
